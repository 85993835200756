<template>
  <div class="brands">
    <h1>For Brands:</h1>
    <p>
      We give brands a creative edge by connecting them with highly influential
      trendsetters who produce original content that drives measurable results.
    </p>
    <p>
      Our approach cuts out the babble and gives you the pipeline to drive
      awareness, endorsements, traffic and enthusiastic consumer engagement. Our
      activations are creative, custom, scalable, and cost-effective.
    </p>
  </div>
  <img src="../assets/image/banner3.png" alt="" />
  <img src="../assets/image/banner4.png" alt="" />
  <img src="../assets/image/banner5.png" alt="" />
</template>

<style lang="less" scoped>
  .brands {
    margin: 0 auto;
    width: 5.65rem;
  }

  h1 {
    font-size: 0.42rem;
    font-family: Arial;
    font-weight: 400;
    color: #000000;
    margin-bottom: 0.35rem;
    margin-top: 0.35rem;
  }

  p {
    font-size: 0.25rem;
    font-family: Arial;
    font-weight: 400;
    color: #000000;
    line-height: 0.4rem;
    margin-bottom: 0.5rem;
  }

  img {
    width: 2.5rem;
  }

</style>
